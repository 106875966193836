<template>
    <div class="booksdata">
         <Header class="hide" />
    <Headers class="dispy" />
        <BannerImage rulename="query_banner " />
        <div class="w">
            <Breadcrumb :crumblist="crumbdata" class="hide" />
            <div class="deti">
                <h3 class="title">{{ result.theTitle }}</h3>
                <div class="small-info">
                    <span v-if=" result.theSource">来源: {{ result.theSource }}</span>
                    <span v-else>来源: 未知</span>
                    <span>时间: {{ result.createTime }}</span>
                    <span>浏览量: {{ result.readCount }}</span>
                </div>
                <div class="desc" v-html="result.theCont"></div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import { getAction } from '@/api/manage'
import BannerImage from '@/components/main/BannerImage'
export default {
    components: {
        Header,
        Footer,
		Breadcrumb,
        BannerImage,
        Headers
	},
	created() {
		this.loadData()
	},
	data() {
		return {
			crumbdata: [
				{name: '信息查询',url: ''},
				{name: '书籍光碟',url: '/books'},
			],
			result: {}
		}
	},
	methods: {
		loadData(){
			getAction('/bc/bookCd/queryById',{
				id: this.$route.query.id
			}).then(res => {
				if(res.success){
					this.result = res.result
					this.crumbdata.push({
						name: res.result.theTitle
					})
				}
			})
		}
	},
};
</script>
<style lang="scss" scoped>
.deti {
    padding: 20px 18px 50px;
    .title {
        text-align: center;
        font-size: 24px;
        font-weight: normal;
        color: #333333;
    }
    .small-info{
        text-align: center;
        margin: 45px 0;
        background-color: #f2f8e8;
        padding: 17px 0;
        color: #999999;
        span{
            margin: 0 20px;
        }
    }
    .desc {
        color: #666666;
		text-align: justify;
		min-height: 500px;
		p{
			img{
				display: block;
				width: 90% !important;
				margin: 0 auto;
			}
		}
    }
}
</style>
